<template>
    <div>
      <page-title :subheading=subheading></page-title>
      
      <!-- Searchable Datatable card -->
		<a-card :bordered="false" class="header-solid px-5" :bodyStyle="{padding: 0, paddingTop: '16px'}">
			<a-row class="pt-4">
				<a-col :span="12">
					<h5 class="font-semibold">List of Sales</h5>
				</a-col>
				<a-col :span="12" class="text-right mb-2">
					<a-button type="primary" :href="`/pharmacy-management/sales/add-new`" class="">
						<a-icon type="plus" theme="outlined" class="text-white mr-0" />Add New 
					</a-button>
				</a-col>

				<a-col :span="24">
					<a-divider class="mt-1 pt-0"/>
				</a-col>
			</a-row>
			

			<div class="mt-3">
				<a-row type="flex" :gutter="24">
					<a-col :span="24" :md="12">
						<a-select v-model="pagination.perPage" :options="pagination.perPageOptions" style="width: 70px"/>
						<label for="" class="ml-10">&nbsp;&nbsp;entries per page</label>
					</a-col>
					<a-col :span="24" :md="12" class="text-right">
                        <a-input-search
                            placeholder="search name..." 
							style="max-width: 250px;" 
							v-model="pagination.search"
                            @search="onSearchItem">
							<template #suffix v-if="pagination.search != null && pagination.search.length > 0">
								<a-icon type="close" theme="outlined" class="text-error mr-3"  @click="onClearSearchItem" />
							</template>
						</a-input-search>
					</a-col>
				</a-row>
			</div>
			
			<div class="mt-5">
				<a-row type="flex" :gutter="24" class="pb-4">
					<a-col :span="24" :md="24">
						<a-table class="mt-20 pb-2"  
							size="middle"
							:responsive="true"
							:bordered="false"
							:columns="columns" 
							:data-source="records" 
							:rowKey="record => record.uuid"
							:loading="pagination.loading" 
							:pagination="false">

								<template slot="s_no" slot-scope="s_no, record, index">
									{{ (pagination.currentPage - 1) * pagination.perPage + index + 1 }}
								</template>

								<template slot="method" slot-scope="method">
									{{ `${method[0].toUpperCase()}${method.substring(1)}` }}
								</template>

								<template slot="items" slot-scope="items">
									<a-badge  
										v-for="item in items"
										:key="item.uuid"
										:number-style="{ backgroundColor: '#11A78B', color: '#FFFFFF'}"
										style="font-size: 12px !important; padding: 2px 5px;" 
										:count="`${item.pharmacy.drug.name} - (${item.quantity})`" />
								</template>

								<template slot="totalAmount" slot-scope="totalAmount, record">
									{{ getTotalAmount(record.items) }}
								</template>

								<template slot="createdAt" slot-scope="createdAt">
									{{ $Moment(createdAt).format("DD MMM YYYY") }}
								</template>

								<template slot="action" slot-scope="action, record">
									<a-button id="txtBtn" type="text" class="pb-0 pt-0 txtBtn text-success" :href="`/pharmacy-management/sales/view/${record.uuid}`">
										<a-icon type="eye" /> View
									</a-button>
								</template>
							
						</a-table>
					</a-col>
					<a-col :span="12" class="mt-2 text-muted">
						<p class="category">Showing {{from + 1}} to {{to}} of {{pagination.total}} entries</p>
					</a-col>
					<a-col :span="12" class="text-right mt-2">
						<a-pagination 
							v-model="pagination.currentPage" 
							:pageSize="pagination.perPage"  
							:total="pagination.total" 
							@change="onCurrentPageChange"/>
					</a-col>
				</a-row>
			</div>
		</a-card>



    </div>
  </template>
  
  <script>
  
    import PageTitle from "../../Layout/Components/PageTitle.vue";

    const columns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
			// width: "5%"
        },
		{
			title: 'CUSTOMER NAME',
			dataIndex: 'customer',
			//width: "15%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'PAYMENT METHOD',
			dataIndex: 'method',
			scopedSlots: { customRender: 'method' },
			// //width: "15%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'ITEMS - (QUANTITY)',
			dataIndex: 'items',
			scopedSlots: { customRender: 'items' },
			// width: "13%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'TOTAL AMOUNT',
			dataIndex: 'totalAmount',
			scopedSlots: { customRender: 'totalAmount' },
			// width: "10%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'CREATED AT',
			dataIndex: 'createdAt',
			scopedSlots: { customRender: 'createdAt' },
			// width: "10%"
		},
		{
			title: 'ACTION',
			dataIndex: 'action',
			scopedSlots: { customRender: 'action' },
			// width: "30%"
			// align: "center"
		},
	];


    const rules = {
      name: [
        { required: true, message: 'Please input Activity name', trigger: 'blur' },
      ],
    };
  
    export default {
		components: {
			PageTitle,
		},
		data() {
			return {

				subheading: 'Sales',

				pagination: {
					search: '',
					loading: false,
					perPage: 10,
					currentPage: 1,
					perPageOptions: [
						{label: "10", value: 10}, 
						{label: "20", value: 20}, 
						{label: "30", value: 30}, 
						{label: "50", value: 50}
					],
					total: 10
				},

				columns,
				message: '',

				records: [],
				medicineRoutes: [],
				medicineTypes: [],
				medicineUnits: [],
				
				form: '',
				rules,
				newItem: {
					uuid: null,
					name: null,
					companyUuid: null,
					codeName: null,
					description: null,
					isCreate: true,
					loading: false,
					showModal: false,
				},

				deleteDataItem: {
					uuid: null,
					showModal: false,
				},

				userDetails: {
					user: null,
					company: null
				},

				notify: {
					text: null,
					type: null,
				},
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'newItemForm' });
		},
      computed: {
            to () {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from () {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
        },
		watch: {
            pagination: {
                handler() {
                    this.getPharmacySales();
                },
            },
            deep: true,
        },
		created() {
			this.form = this.$form.createForm(this, { name: 'newItemForm' })
            this.getUserDetails();
			this.getPharmacySales();
		},
		methods: {

			onSearchItem() {
				if(this.pagination.search != null && this.pagination.search.length > 0) {
					this.pagination.currentPage = 1;
					this.pagination.perPage = 10;
					
					this.getPharmacySales()
				}else{
					this.notify.text = "Please enter something to search"
					this.notify.type = "error"

					this.$notify(this.notify)
				}
			},

			onClearSearchItem() {
				this.pagination.search = '';

				this.getPharmacySales();
			},

			onCurrentPageChange(value) {
				this.pagination.currentPage = value;

				this.getPharmacySales();
			},

			onPerPageChange(value) {
                this.pagination.perPage = value;

                this.getPharmacySales();
            },


			getTotalAmount(items) {
				let totalAmount = 0.0

				items.map(item => {
					totalAmount += parseFloat(item.amount)
				});

				return parseInt(totalAmount.toFixed(2)).toLocaleString();
			},


            async getUserDetails() {
                let userDetails = await JSON.parse(localStorage.getItem("pharmUser"));

                if(userDetails != null) {
                    this.userDetails.user = userDetails.user;
                    this.userDetails.company = userDetails.company;
                }else {
                    this.$router.push(`/`);
                }
            },

			async getPharmacySales() {
                this.pagination.loading = true;

                const { currentPage, perPage, search } = this.pagination;

                let userDetails = await JSON.parse(localStorage.getItem("pharmUser"));

                let companyUuid = userDetails.company != null ? userDetails.company.uuid : null

                let url = `${this.$BACKEND_URL}/pharmacy-sales/${companyUuid}?search=${search}&page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        if(response.data.data.length > 0) {
                            this.records = response.data.data;
                            this.pagination.totalPages = response.data.totalPages;
                            this.pagination.total = response.data.total;
                            this.pagination.currentPage = response.data. currentPage;
                        }else{
                            this.notify.text = "There is no data available";
							this.notify.type = "success";
                            this.$notify(this.notify);
                        }
                    }
                    this.pagination.loading = false;
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    // eslint-disable-next-line no-console
                    // console.log(error)

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)

                    this.pagination.loading = false;
                });
            },


		},
    }
  </script>
  
  <style lang="scss">
  
  #textBtn, #textBtn:hover {
      border: none !important;
  }
</style>